import 'core-js/modules/es.promise';
import 'core-js/modules/es.object.assign';

// TODO: this should be the client.js (which creates the iframe)

window.QuadiaPlayer = window.QuadiaPlayer || {
  players: {},
  callbacks: {},
  needsInit: true,
  '$on': function (event, callback) {
    if (typeof this.callbacks[event] === 'undefined') {
      this.callbacks[event] = []
    }
    this.callbacks[event].push(callback);
    return { 'type': event, 'callback': callback };
  },
  '$off': function (event) {
    if (typeof event === 'string' && typeof this.callbacks[event] !== 'undefined') {
      delete this.callbacks[event];
    } else if (event.type && event.callback && typeof this.callbacks[event.type] !== 'undefined') {
      this.callbacks[event.type] = this.callbacks[event.type].filter(function (value, index, arr) { return value !== event.callback; });
    }
  }
}

function addStyle(me) {
  var css = `
iframe.d0n1TmSsw1thqu4di4pl4y4r{ 
  width: 100%;
  height: 100%;
}`;
  var style = document.createElement('style');
  style.appendChild(document.createTextNode(css));
  me.parentNode.appendChild(style)
}

function addIframe(me) {
  if (!me.dataset.id) {
    me.setAttribute('data-id', Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10));
  }
  var id = me.getAttribute('data-id');
  var frame = document.createElement('iframe');
  var host = process.env.VUE_APP_SCRIPT_HOST;
  host = host.replace(/\/$/, '');
  frame.setAttribute('src', host + '/index-loader.html?id=' + me.dataset.id);
  frame.setAttribute('allowfullscreen', true);
  frame.setAttribute('webkitallowfullscreen', true);
  frame.setAttribute('frameborder', 0);
  frame.setAttribute('allow', 'autoplay; fullscreen; clipboard-write');
  if (me.dataset.height) {
    frame.setAttribute('height', isNaN(me.dataset.height) ? me.dataset.height : me.dataset.height + 'px');
  }
  if (me.dataset.width) {
    frame.setAttribute('width', isNaN(me.dataset.width) ? me.dataset.width : me.dataset.width + 'px');
  } else {
    frame.setAttribute('width', '100%');
  }
  frame.setAttribute('id', id);
  me.parentNode.appendChild(frame)
  window.QuadiaPlayer.players[id] = {
    '$on': function (event, callback) {
      if (typeof this.callbacks[event] === 'undefined') {
        this.callbacks[event] = []
      }
      this.callbacks[event].push(callback);
      return { 'type': event, 'callback': callback };
    },
    '$off': function (event) {
      if (typeof event === 'string' && typeof this.callbacks[event] !== 'undefined') {
        delete this.callbacks[event];
      } else if (event.type && event.callback && typeof this.callbacks[event.type] !== 'undefined') {
        this.callbacks[event.type] = this.callbacks[event.type].filter(function (value, index, arr) { return value !== event.callback; });
      }
    },
    '$emit': function (event, params) {
      this.iframe.contentWindow.postMessage({
        '$emit': event,
        params: params
      }, '*')
    },
    iframe: frame,
    dataset: Object.assign({}, me.dataset, { referrer: me.dataset.referrer ? me.dataset.referrer : window.location.href }),
    callbacks: {},
    get: function (key) {
      return new Promise((resolve, reject) => {
        var eventID = Math.random().toString(36)
        var listener = function (e) {
          if (e.data.key === key && e.data.id === eventID) {
            window.removeEventListener('message', listener, false)
            resolve(e.data.value)
          }
        }
        window.addEventListener('message', listener, false)
        this.iframe.contentWindow.postMessage({ get: key, id: eventID }, '*')
      })
    }
  }
}
var forcedFullscreen = {};
var me = null

if (document.currentScript) {
  me = document.currentScript
  addIframe(me)
  addStyle(me)
  me.setAttribute('data-initialized', true)
} else {
  var scripts = document.getElementsByTagName('script');
  for (var i = 0; i < scripts.length; ++i) {
    var scriptSrc = scripts[i].src.indexOf('//') !== -1 ? scripts[i].src : location.protocol + '//' + location.host + scripts[i].src
    me = scripts[i]
    if (scriptSrc.indexOf(process.env.VUE_APP_SCRIPT_NAME) !== -1 && scriptSrc.indexOf(process.env.VUE_APP_SCRIPT_HOST) !== -1 && me.getAttribute('data-initialized') === null) {
      addIframe(me)
      addStyle(me)
      me.setAttribute('data-initialized', true)
    }
  }
}

if (window.QuadiaPlayer.needsInit) {
  // only registere listener once
  delete window.QuadiaPlayer.needsInit;
  window.addEventListener('message', receiveMessage, false);
}

function receiveMessage(event) {
  if (!window.QuadiaPlayer) {
    console.log('Non existent window.QuadiaPlayer');
    console.log(event);
    return;
  }
  if (process.env.VUE_APP_SCRIPT_HOST.indexOf(event.origin) !== -1) {
    switch (event.data.type) {
      case 'ready':
        window.QuadiaPlayer.players[event.data.id].iframe.contentWindow.postMessage({
          dataset: JSON.stringify(window.QuadiaPlayer.players[event.data.id].dataset)
        }, '*')
        break;
      case 'heightChange':
        if (!forcedFullscreen[event.data.id]) {
          window.QuadiaPlayer.players[event.data.id].iframe.style.height = event.data.height + 'px'
        }
        break;
      case 'widthChange':
        window.QuadiaPlayer.players[event.data.id].iframe.width = event.data.data;
        break;
      case 'forceFullscreen':
        var node = window.QuadiaPlayer.players[event.data.id].iframe;
        forcedFullscreen[event.data.id] = event.data.data;
        if (event.data.data) {
          // TODO set meta viewport for iPhones
          node.setAttribute('data-old-height', node.style.height);
          node.style.height = '100%';
          node.setAttribute('data-old-width', node.width);
          node.style.width = '100%';
          node.style.position = 'fixed';
          node.style.top = 0;
          node.style.bottom = 0;
          node.style.left = 0;
          node.style.right = 0;
          node.style.zIndex = 999999;
          window.scrollTo(0, 1);
        } else {
          if (node.getAttribute('data-old-height')) {
            node.style.height = node.getAttribute('data-old-height');
          }
          if (node.getAttribute('data-old-width')) {
            node.width = node.getAttribute('data-old-width');
          }
          node.style.position = '';
          node.style.top = '';
          node.style.bottom = '';
          node.style.left = '';
          node.style.right = '';
          node.style.zIndex = '';
        }
        if (window.QuadiaPlayer.players[event.data.id] && typeof window.QuadiaPlayer.players[event.data.id].callbacks[event.data.type] !== 'undefined') {
          window.QuadiaPlayer.players[event.data.id].callbacks[event.data.type].forEach(callback => {
            callback(event.data.data)
          });
        }
        if (window.QuadiaPlayer.callbacks && typeof window.QuadiaPlayer.callbacks[event.data.type] !== 'undefined') {
          window.QuadiaPlayer.callbacks[event.data.type].forEach(callback => {
            callback(event.data.id, event.data.data);
          });
        }
        break;
      // TODO list events which are available for the outside to listen
      case 'set-iframe-class':
        window.QuadiaPlayer.players[event.data.id].iframe.classList.add('d0n1TmSsw1thqu4di4pl4y4r');
        break;
      case 'play':
      case 'pause':
      case 'stop':
      case 'seek':
      case 'playerReady':
      case 'firstFrame':
      case 'timeChange':
      case 'complete':
      case 'theaterMode':
      case 'externalFullscreen':
        if (window.QuadiaPlayer.players[event.data.id] && typeof window.QuadiaPlayer.players[event.data.id].callbacks[event.data.type] !== 'undefined') {
          window.QuadiaPlayer.players[event.data.id].callbacks[event.data.type].forEach(callback => {
            callback(event.data.data)
          });
        }
        if (window.QuadiaPlayer.callbacks && typeof window.QuadiaPlayer.callbacks[event.data.type] !== 'undefined') {
          window.QuadiaPlayer.callbacks[event.data.type].forEach(callback => {
            callback(event.data.id, event.data.data);
          });
        }
        break;
      case 'error': {
          const callbacks = window.QuadiaPlayer.players[event.data.id].callbacks;
          if (callbacks && typeof callbacks[event.data.type] !== 'undefined') {
            callbacks[event.data.type].forEach(c => {
              c(event.data.id, event.data.error);
            });
          }
          const managerCallbacks = window.QuadiaPlayer.callbacks;
          if (managerCallbacks && typeof managerCallbacks[event.data.type] !== 'undefined') {
            managerCallbacks[event.data.type].forEach(c => {
              c(event.data.id, event.data.error);
            });
          }
        }
        break;
    }
  }
}
